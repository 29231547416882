<template>
  <v-main>
    <v-row no-gutters class="h-100">
      <v-col no-guttore cols="12" lg="6" class="position-relative">
        <v-toolbar flat>
          <v-list-item-icon @click="$next('/')" class="cursor-pointer ma-0 ma-lg-4">
            <tt-icon width="48" icon="logo" />
          </v-list-item-icon>
        </v-toolbar>

        <Base class="ma-4 ma-md-auto ma-lg-8 w-md-50" :activeTab="activeTab" />
      </v-col>
      <v-col no-gutters cols="6" class="hidden-md-and-down">
        <v-img height="100vh" src="@/assets/images/promoter-login.svg"> </v-img>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import Base from './base';

export default {
  components: {
    Base,
  },
  props: ['activeTab'],
};
</script>
